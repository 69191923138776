import React from 'react';
import {
  MDBSideNavLink,
  MDBSideNavNav,
  MDBSideNav,
} from 'mdbreact';
import { applicationLogo, isRtl, Language } from '../config';
import { useUserValue } from '../features/login';
import { useTranslation } from 'react-i18next';
import '../css/right.css'

export default function SideNavigation(props) {
  const { onLinkClick } = props;
  const { t } = useTranslation(['home']); // "home" is namespace
  const { user } = useUserValue();
  const inlineStyle = isRtl() ?
    { width: '8px', left: '20.5px', position: 'absolute' } :
    { width: '8px', right: '20.5px', position: 'absolute' }
  const renderComponent = () => {
    switch (user?.role) {
      case 1://admin
        return <MDBSideNavNav className='' style={{ marginTop: '75px' }}>
          <MDBSideNavLink exact topLevel={true} to='/' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Advertise')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Report' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Report.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Report')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Chart' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Chart.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Chart')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          {user?.modules?.find(x => x === 'trend') ?
            <MDBSideNavLink topLevel={true} to='/Map' onClick={onLinkClick}>
              <div style={{ padding: '20px' }}>
                <img alt="img" className='icon' src={require('../assets/img/Trends.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
                {t('Map')}
                <img alt="img" className='caret'
                  style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
              </div>
            </MDBSideNavLink>
            : <></>
          }
          <MDBSideNavLink topLevel={true} to='/Live' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Live.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Live')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Archive' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Archive.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Archive')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/box' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Box')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/conductor' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Conductor')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Users' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/AllUsers.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('All users')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Companies' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Company.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Companies')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Categories' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Category.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Categories')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Profile' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Profile.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Profile')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
        </MDBSideNavNav>
      case 2://supervisor
        return <MDBSideNavNav className='' style={{ marginTop: '75px' }}>
          <MDBSideNavLink exact topLevel={true} to='/' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Advertise')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Report' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Report.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Report')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Chart' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Chart.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Chart')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          {user?.modules?.find(x => x === 'trend') ?
            <MDBSideNavLink topLevel={true} to='/Map' onClick={onLinkClick} >
              <div style={{ padding: '20px' }}>
                <img alt="img" className='icon' src={require('../assets/img/Trends.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
                {t('Map')}
                <img alt="img" className='caret'
                  style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
              </div>
            </MDBSideNavLink>
            : <></>
          }
          <MDBSideNavLink topLevel={true} to='/Live' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Live.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Live')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Archive' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Archive.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Archive')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/box' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Box')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/conductor' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Conductor')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Companies' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Company.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Companies')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Categories' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Category.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Categories')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Profile' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Profile.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Profile')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
        </MDBSideNavNav>
      case 3://operator
        return <MDBSideNavNav className='' style={{ marginTop: '75px' }}>
          <MDBSideNavLink exact topLevel={true} to='/' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Advertise')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Report' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Report.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Report')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Live' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Live.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Live')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Archive' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Archive.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Archive')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/box' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Box')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/conductor' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Conductor')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Companies' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Company.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Companies')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Categories' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Category.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Categories')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Profile' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Profile.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Profile')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
        </MDBSideNavNav>
      case 4://customer
        return <MDBSideNavNav className='' style={{ marginTop: '75px' }}>
          <MDBSideNavLink exact topLevel={true} to='/' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Advertise.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Advertise')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Report' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Report.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Report')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Chart' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Chart.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Chart')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
          <MDBSideNavLink topLevel={true} to='/Profile' onClick={onLinkClick} >
            <div style={{ padding: '20px' }}>
              <img alt="img" className='icon' src={require('../assets/img/Profile.png')} style={isRtl() ? { marginLeft: '16px' } : { marginRight: '16px' }} />
              {t('Profile')}
              <img alt="img" className='caret'
                style={inlineStyle} src={isRtl() ? require('../assets/img/menu-caret-left.png') : require('../assets/img/menu-caret-right.png')} />
            </div>
          </MDBSideNavLink>
        </MDBSideNavNav>
      default:

    }
  }
  return (
    <div className={Language} >
      <MDBSideNav
        mask='strong'
        fixed
        breakWidth={props.breakWidth}
        triggerOpening={props.triggerOpening}
        style={{ transition: 'padding-right .3s' }}
        right={isRtl()}
      >
        <img alt="img" src={applicationLogo} className='d-block mx-auto mb-5' style={{ width: '150px', height: '116px', marginTop: '90px' }} />
        {renderComponent()}
      </MDBSideNav>
    </div >
  );
}

